<template>
  <div>
    <!-- Course dropdown -->
    <!--    <b-dropdown-->
    <!--      id="dropdown-1"-->
    <!--      v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
    <!--      :text="`${$t('Course')}: ${course.course_name}`"-->
    <!--      variant="primary"-->
    <!--      class="mb-1"-->
    <!--    >-->
    <!--      <b-dropdown-item-->
    <!--        v-for="course in courses"-->
    <!--        :key="course.id"-->
    <!--        @click="getCourse(course)"-->
    <!--      >-->
    <!--        {{ course.course_name }}-->
    <!--      </b-dropdown-item>-->
    <!--    </b-dropdown>-->
    <b-row>
      <b-col cols="12">
        <sessions-list :is-student="true" />
      </b-col>
      <!--      <b-col-->
      <!--        lg="6"-->
      <!--        md="12"-->
      <!--      >-->
      <!--        <dashboard-course-card :course="course" />-->
      <!--      </b-col>-->
      <b-col
        lg="6"
        md="12"
      >
        <dashboard-course-timeline />
      </b-col>
      <b-col
        lg="6"
        md="12"
      >
        <dashboard-congratulation />
      </b-col>
      <!--      <b-col-->
      <!--        lg="6"-->
      <!--        md="12"-->
      <!--      >-->
      <!--        <dashboard-chat />-->
      <!--      </b-col>-->
    </b-row>
  </div>
</template>
<script>
/* eslint-disable object-curly-newline, no-return-assign, prefer-destructuring */
import { BRow, BCol,
  // BDropdown,
  // BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SessionsList from '@/views/teacher/dashboard/SessionsList.vue'
import DashboardCongratulation from './DashbiardCongratulation.vue'
import DashboardCourseTimeline from './DahboardCourseTimeline.vue'
// import StudentSurvey from './StudentSurvey.vue'

export default {
  components: {
    BRow,
    BCol,
    // BDropdown,
    // BDropdownItem,
    DashboardCongratulation,
    DashboardCourseTimeline,
    SessionsList,
    // StudentSurvey,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      courses: [],
      course: {},
    }
  },
  // mounted() {
  //   this.$bvModal.show('survey1-modal')
  // },
  created() {
    this.$http.get('/student/dashboard').then(res => {
      this.courses = res.data.courses
      this.course = res.data.courses[0]
    })
    // setTimeout(() => {
    //   this.$bvModal.show('modal-1')
    // }, 1500)
  },
  methods: {
    getCourse(course) {
      this.course = course
    },
  },
}
</script>
